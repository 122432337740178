export const ignoredSentryErrors = [
  "Network Error",
  "timeout of 0ms exceeded",
  "Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type",
  "Loading chunk",
  "Unexpected token '<'",
  /^application '@ottu-mf\/(navigation|frontend|fnb_frontend)' died in status LOADING_SOURCE_CODE/,
  /^application '@ottu-mf\/(navigation|frontend|fnb_frontend)' died in status LOADING_SOURCE_CODE: undefined is not an object \(evaluating 'n.bootstrap'\)/,
];

export const MERCHANT_ADMIN_ROLE = "Merchantadmin";
